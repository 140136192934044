import { isEqualWith, isObject, reduce, transform, isEmpty, isArray } from 'lodash';
import { COMPANY_MEMBER_TYPE, TYPE_MEMBER_CORPORATION, TYPE_MEMBER_INDIVIDUAL } from '../constants';
import { subDays, isPast, parseISO, add } from 'date-fns';
import { purple } from '@mui/material/colors';
import moment from 'moment';

export { getLayoutedElements } from './react-flow-utils';
export { a11yProps } from './dom';
export * from './datetime';
export * from './enum';
export * from './tree';

export const isValidDate = function (date) {
    return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
};

export const isProduction = () => {
    return process.env.REACT_APP_PRODUCTION === 'true';
};

export const difference = (object, base, isAbsolute = false) => {
    return reduce(
        object,
        (result, value, key) => {
            if (!isEqualWith(value, base[key], (x, y) => (!isAbsolute ? x == y : x === y))) {
                result[key] =
                    isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
            }
            return result;
        },
        {}
    );
};

export function hasChildren(item) {
    const { items: children } = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}

export function cleanEmptyObject(el) {
    function internalClean(el) {
        return transform(el, function (result, value, key) {
            var isCollection = isObject(value);
            var cleaned = isCollection ? internalClean(value) : value;

            if (isCollection && isEmpty(cleaned)) {
                return;
            }

            isArray(result) ? result.push(cleaned) : (result[key] = cleaned);
        });
    }

    return isObject(el) ? internalClean(el) : el;
}

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    if (typeof name !== 'string') name = '*';
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')[0][0]}`
    };
}

export function findObjectByKey(array, key, value) {
    var o;
    array.some(function iter(a) {
        if (a[key] === value) {
            o = a;
            return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
    });
    return o;
}

export function setPageTitle(text) {
    const titleElement = document.getElementById('page-title');
    let baseText = titleElement.getAttribute('data-text');
    titleElement.innerText = `(${text}) ${baseText}`;
}

/**
 * Check url is valid http url or not
 * @param string url to check
 * @returns {boolean}
 */
export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

export const exchange = (amount, ratio, min_unit, min_amount) => {
    let amount_ex = Math.abs(amount) * ratio;
    amount_ex =
        (Math.floor(amount_ex / min_unit) + Math.round((amount_ex % min_unit) / min_unit)) *
        min_unit;
    if (amount_ex < min_amount) amount_ex = min_amount;
    if (amount == 0) amount_ex = 0;
    if (amount < 0) {
        amount_ex = amount_ex * -1;
    }
    return amount_ex;
};

export const generatePassword = (
    length,
    wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) => {
    return Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join('');
};
/**
 * get color by status invoice
 * @param status invoice status
 * @returns {string}
 */
export const colorByStatus = (status) => {
    switch (status) {
        case 1:
            return 'warning';
        case 2:
            return 'primary';
        case 3:
            return 'error';
        case 5:
            return 'success';
        default:
            return 'grey';
    }
};

/**
 * Get array of years from startYear to now
 * @param  startYear
 * @returns
 */
export const getRangeYear = (startYear) => {
    let currentYear = new Date().getFullYear(),
        years = [];
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years;
};

export const styleProcess = (name, data) => {
    switch (name) {
        case 'process_status_bgColor':
            let processType = data.type || data.process_step_type_id;
            switch (processType) {
                case 1: // incorporation
                    return '#34bfa3';
                case 2: // banking
                    return 'deepskyblue';
                case 3: // compliance calendar
                    return purple[500];
                case 15: // accounting & auditing
                case 21: // accounting & auditing Hong Kong
                case 42: // accounting & auditing Singapore
                    return '#FF5E00';
                default:
                    return '#ffb822';
            }
        case 'process_status_color':
            if ([2, 23, 21, 22].includes(data.id)) return '#f4516c';
            return 'white';
        case 'service_type_color': {
            switch (data) {
                case 1: // incorporation
                case 18: // annual renewal
                case 50: //incorporation sg
                    return '#34bfa3';
                case 2: // banking
                    return 'deepskyblue';
                case 34: // es + tin
                case 24: // change name
                case 39: // change officer
                case 38: // change company address
                case 37: // round chop
                case 23: // document retrieval
                case 35: // physical shipping
                    return '#ffb822';
                default:
                    return '#edf0f3';
            }
        }
    }
};

/**
 * get color by status customer
 * @param status customer status
 * @returns {string}
 */
export const colorByCustomerStatus = (status) => {
    switch (status) {
        case 0:
            return 'success';
        case 1:
            return 'primary';
        case 2:
            return 'error';
        default:
            return 'grey';
    }
};

export const roundTwoDecimal = (number) => {
    return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2 });
};

/**
 * get color by support_case_priority
 * @param priority priority
 * @returns {string}
 */
export const colorByPriority = (priority) => {
    switch (priority) {
        case 1:
            return 'default';
        case 2:
            return 'warning';
        case 3:
            return 'error';
        default:
            return 'default';
    }
};

/**
 * get color by support_case_status
 * @param status status
 * @returns {string}
 */
export const colorBySupportStatus = (status) => {
    switch (status) {
        case 1:
            return 'warning';
        case 2:
            return 'default';
        case 3:
            return 'success';
        default:
            return 'default';
    }
};

/**
 * replace date if it is string and have right format
 * @param date date check if it is string
 * date.match(/^\d{4}-\d{2}-\d{2}$/) regex for yyyy-MM-dd
 * date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/) regex for yyyy-MM-dd HH:ii:ss
 * @returns {string}
 */
export const getDateWithFormat = (date) => {
    if (typeof date === 'string')
        if (
            date.match(/^\d{4}-\d{2}-\d{2}$/) ||
            date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)
        ) {
            return new Date(date.replaceAll('-', '/'));
        }

    return date;
};

export const getFullName = (people, withMail = false, hasShowSelectFullName = false) => {
    if (!people) return '';

    if (people?.type_member_id === TYPE_MEMBER_CORPORATION) return people?.corporation_name;

    return (
        (people?.full_name || [people?.first_name, people?.last_name].join(' ')) +
        (withMail ? (people?.email ? ` (${people?.email})` : '') : '')
    );
};

export const getAgentName = (agent) => {
    let temp = [agent.name];
    if (agent.email) {
        temp.push(`(${agent.email})`);
    }
    if (agent.is_has_nominee) {
        temp.push(`- nominee`);
    }
    return temp.join(' ');
};

export const getDateForFilter = (date, type = 'start') => {
    date = getDateWithFormat(date);

    if (typeof date !== 'object') date = new Date(date);

    const dateString = `${date.getFullYear()}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${
        type === 'start' ? '00:00:00' : '23:59:59'
    } +00`;

    return new Date(dateString);
};

export const calculateNumbers = (...arg) => {
    return [...arg].reduce((acc, cur) => {
        return acc + (isNaN(parseFloat(cur)) ? 0 : parseFloat(cur));
    }, 0);
};

const turnToDefault = (func, def) => {
    try {
        let data = func();
        return data || def;
    } catch (error) {
        return def;
    }
};

export const getOptionFullName = (option) => {
    return (
        ([option?.Person?.first_name, option?.Person?.last_name].join(' ') || 'None') +
        (option?.username ? ' (' + (option?.username || '') + ')' : '')
    );
};

export const getColorTitleTaskNote = (role) => {
    let color = '';
    switch (role) {
        case 'RD Manager':
        case 'Compliance Leader':
        case 'Compliance Staff':
        case 'Legal Leader':
        case 'Legal Staff':
            color = 'orange';
            break;

        case 'Customer Service Manager':
        case 'Customer Service Leader - Team 1':
        case 'Customer Service Staff - Team 1':
        case 'Customer Service Leader - Team 2':
        case 'Customer Service Staff - Team 2':
        case 'Customer Service Leader - Team 3':
        case 'Customer Service Staff - Team 3':
            color = 'purple';
            break;

        default:
            color = 'text.primary';
            break;
    }

    return color;
};

export const getColorTitleStepNote = (role) => {
    let color = '';
    switch (role) {
        case 'HR Staff':
        case 'Customer Service Staff - Team 1':
        case 'Customer Service Staff - Team 2':
        case 'Customer Service Staff - Team 3':
        case 'RMD Staff':
        case 'Accounting Staff':
        case 'Internal Bookkeeper Staff':
        case 'Compliance Staff':
        case 'Legal Staff':
            color = 'orange';
            break;

        case 'IT Managers':
        case 'HR Manager':
        case 'Customer Service Manager':
        case 'RMD Manager':
        case 'RD Manager':
            color = 'purple';
            break;

        case 'Customer Service Leader - Team 1':
        case 'Customer Service Leader - Team 2':
        case 'Customer Service Leader - Team 3':
        case 'Accouting Leader':
        case 'Internal Bookkeeper Leader':
        case 'Compliance Leader':
        case 'Legal Leader':
            color = 'green';
            break;

        default:
            color = 'text.primary';
            break;
    }

    return color;
};

/**
 * @param {*} currentDate date object - ngày hiện tại
 * @param {*} subDay number - số ngày từ ngày hiện tại
 */
export const showExpiryDate = (currentDate, subDay) => {
    if (currentDate === null) return false;
    const date = new Date(currentDate);

    const pastDate = subDays(date, subDay);
    if (isPast(pastDate)) {
        return true;
    }

    return false;
};

/**
 *
 * @param {*} currentDate date object - ngày hiện tại
 */
export const isPassportExpired = (currentDate) => {
    if (currentDate === null) return false;
    const date = new Date(currentDate);

    const today = add(date, { days: 1 });
    if (isPast(today)) {
        return true;
    }

    return false;
};

export const formatTimeWhatsApp = (date) => {
    if (date === null) return false;
    const referenceDate = moment(date);

    const today = referenceDate.isSame(moment().clone().startOf('day'), 'd');
    const yesterday = referenceDate.isSame(
        moment().clone().subtract(1, 'days').startOf('day'),
        'd'
    );
    // const weekOld = referenceDate.isAfter(moment().clone().subtract(7, 'days').startOf('day'))

    // Today
    if (today) {
        return referenceDate.format('hh:mm A');
    }

    // Yesterday
    if (yesterday) {
        return 'Yesterday ' + referenceDate.format('hh:mm A');
    }
    // 7 days ago from today
    // if (weekOld) {
    //     return referenceDate.format('ddd');
    // }

    return referenceDate.format('hh:mm A, DD MMM yyyy');
};

export const convertFileSize = (size) => {
    if (!size) return false;

    const length = size?.toString()?.length;
    let convertSize = 0;

    // MB
    if (length >= 7) {
        convertSize = (size / 1048576).toFixed();
        return `${convertSize} MB`;
    }

    // KB
    if (length >= 4) {
        convertSize = (size / 1024).toFixed();
        return `${convertSize} KB`;
    }

    // byte
    return `${size} byte`;
};

export const convertStringToURL = (content) => {
    const URL_REGEX =
        /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

    if (!content) return '';
    if (content.includes('@')) return content;
    return content?.replaceAll(URL_REGEX, function (url) {
        if (url.search('http') === 0)
            return (
                '<a href="' +
                url +
                '" class="url_text text-sky-500 hover:text-sky-600" target="_blank">' +
                ' ' +
                url +
                '</a>'
            );
        return (
            '<a href="https://' +
            url +
            '" class="url_text text-sky-500 hover:text-sky-600" target="_blank">' +
            ' ' +
            url +
            '</a>'
        );
    });
};

export const formatNumber = (value) => {
    if (value) {
        if (Number.isInteger(value)) {
            return value;
        } else {
            return Number.parseFloat(Number(value)?.toFixed(2));
        }
    } else {
        return '';
    }
};

export const checkValidation = (business_activity_array, operation_countries_array) => {
    let result = 'pass';
    if (business_activity_array.length > 15) {
        result = 'Please select at most 15 business activities.';
    }
    if (operation_countries_array.length > 30) {
        result = 'Please select at most 30 country of operation.';
    }
    if (operation_countries_array.length > 30 && business_activity_array.length > 15) {
        result =
            'Please select at most 30 country of operation. Please select at most 15 business activities.';
    }

    return result;
};
