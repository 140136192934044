import React from 'react';
import { TextField } from '@mui/material';
import { debounce, isNumber } from 'lodash';
import { Controller } from 'react-hook-form';
import { formatNumber } from 'helper';

function InputField({
    form,
    label,
    name,
    disabled = false,
    readOnly = false,
    type = 'text',
    onChange,
    debounceTime = 0,
    hasResize,
    ...props
}) {
    const allowOnlyNumber = (e, field) => {
        const { value } = e.target;

        const formattedValue = value.replace(',', '.');

        if (/^\d*(\.?\d{0,2})?$/.test(formattedValue)) {
            return value;
        } else {
            return field.value;
        }
    };

    const handleChange = (e, field) => {
        if (type === 'number') {
            field.onChange(allowOnlyNumber(e, field));
        } else {
            field.onChange(e);
        }
    };

    const callDebounce = debounce((e) => {
        onChange(e);
    }, debounceTime);
    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field, fieldState: { isDirty, invalid, isTouched, error } }) => {
                let InputProps = {
                    readOnly: readOnly
                };

                if (props.InputProps) {
                    InputProps = {
                        ...props.InputProps,
                        ...InputProps
                    };
                }

                return (
                    <TextField
                        fullWidth={true}
                        {...field}
                        error={invalid}
                        label={label}
                        onChange={(e) => {
                            handleChange(e, field);
                            onChange && callDebounce(e);
                        }}
                        disabled={disabled}
                        helperText={error?.message || ''}
                        type={type}
                        value={field.value ?? ''}
                        variant="standard"
                        {...props}
                        sx={{
                            ...(props.multiline
                                ? {
                                      '.MuiInput-input.MuiInputBase-input.MuiInputBase-inputMultiline':
                                          {
                                              resize: 'auto'
                                          }
                                  }
                                : undefined),
                            ...props.sx,
                            '.MuiInputBase-root': {
                                lineHeight: '24px'
                            }
                        }}
                        {...(type === 'number' && {
                            type: 'text',
                            value: isNumber(field.value) ? formatNumber(field.value) : field?.value,
                            onKeyDown: (e) =>
                                ['e', 'E', '+', '-', ',', ' '].includes(e.key) && e.preventDefault()
                        })}
                        InputProps={InputProps}
                    />
                );
            }}
        />
    );
}

export default InputField;
