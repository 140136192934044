export * from './country';
export * from './preview-mail';
export * from './member-type';
export * from './select-options';
export * from './bankid';
export * from './order-status';
export * from './website';
export * from './permissions';
export * from './roles-name';
export * from './note-type-id';

export const LS_REMEMBER_PATH = 'LS_REMEMBER_PATH';

export const SHORT_MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const DEBOUNCE_TIME = 700;
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';
export const DATE_TIME_FORMAT_NOTE = 'HH:mm:ss dd/MM/yyy';
export const MONTH_YEAR_FORMAT = 'MM/yyyy';
export const MONTH_YEAR_DB_FORMAT = 'yyyy-MM';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_DB_FORMAT = 'yyyy-MM-dd';
export const MASK_DATE_FORMAT = [
    /\d/,
    /\d/,
    ' ',
    /[A-Za-z]/,
    /[A-Za-z]/,
    /[A-Za-z]/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
export const MASK_YEAR_FORMAT = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
export const MASK_MONTH_YEAR_FORMAT = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const MASK_DATE_TIME_FORMAT = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ':',
    /\d/,
    /\d/
];

export const ALPHABET_NUMBER_CHARACTERS =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const SERVICE_TYPE = {
    PHYSICAL_SHIPPING: 35,
    ROUND_CHOP: 37,
    DOCUMENT_RETRIEVAL: 23,
    DISCOUNT: 19,
    POST_INCORP: 32,
    POST_INCORP2: 33,
    CHANGE_COMPANY_NAME: 24,
    CHANGE_OFFICERS: 39,
    CHANGE_COMPANY_ADDRESS: 38,
    COMPLIANCE_SERVICES: 34
};

export const TINYMCE_CONFIG = {
    height: 500,
    plugins: [
        'advlist autolink lists link image charmap hr anchor',
        'searchreplace wordcount code',
        'insertdatetime media nonbreaking table',
        'template paste textpattern textcolor'
    ],
    toolbar:
        'formatselect | table | bold italic underline | link image| forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | removeformat',
    menubar: false,
    statusbar: false,
    paste_data_images: true,
    image_title: true,
    textcolor_cols: '5',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
};

// =======================================================> Sử dụng ở list task label
export const TYPE_PROCESS = {
    INCORPORATION: 1,
    BANKING: 2,
    ADDITIONAL_SERVICE: 3,
    INCORPORATION_DASHBOARD: 4,
    BANKING_DASHBOARD: 5,
    ADDITIONAL_SERIVCE_DASHBOARD: 6,
    DOCUMENT_RETRIEVAL: 7,
    DOCUMENT_RETRIEVAL_DASHBOARD: 8,
    ROUND_CHOP_PHYSICAL_SHIPPING: 9,
    ROUND_CHOP_PHYSICAL_SHIPPING_DASHBOARD: 10,
    DOCUMENT_SHIPPING: 11,
    DOCUMENT_SHIPPING_DASHBOARD: 12,
    POST_INCORPORATION: 13,
    ACCOUNTING_AUDITING: 15,
    ACCOUNTING_AUDITING_HK: 21,
    ACCOUNTING_AUDITING_SG: 42
};

export const RENEWAL_HK_MEMBER_ID = 58;

export const TYPE_PROCESS_PORTAL = {
    INCORPORATION: 4,
    BANKING: 5,
    ADDITIONAL_SERVICE: 6
};

export const TYPE_PROCESS_STEP_NAME = {
    [TYPE_PROCESS.INCORPORATION]: 'Incorporation',
    [TYPE_PROCESS.BANKING]: 'Banking',
    [TYPE_PROCESS.ADDITIONAL_SERVICE]: 'Additional Service',
    [TYPE_PROCESS.DOCUMENT_RETRIEVAL]: 'Document Retrieval',
    [TYPE_PROCESS.ROUND_CHOP_PHYSICAL_SHIPPING]: 'Round Chop & Physical Shipping',
    [TYPE_PROCESS.DOCUMENT_SHIPPING]: 'Document & Shipping',
    [TYPE_PROCESS.POST_INCORPORATION]: 'Post-incorporation',
    [TYPE_PROCESS.ACCOUNTING_AUDITING]: 'Accounting & Auditing',
    [TYPE_PROCESS.ACCOUNTING_AUDITING_HK]: 'Accounting & Auditing',
    [TYPE_PROCESS.ACCOUNTING_AUDITING_SG]: 'Accounting & Auditing'
};

export const SPECIAL_LLC_COUNTRIES = [
    { id: 23, name: 'Belize' },
    { id: 205, name: 'St. Kitts & Nevis' },
    { id: 209, name: 'St. Vincent & Grenadines' },
    { id: 250, name: 'Delaware' }
];
export const ENTITY_TYPES = {
    LLC: { id: 11, name: 'Limited Liability Company (LLC)' },
    LLP: { id: 7, name: 'Limited Liability Partnership (LLP)' }
};

export const MANAGER_ROLES = [
    'Director',
    'Vice-Director',
    'Customer Service - Manager',
    'RD - Manager',
    'Accounting - Manager'
];

export const BOOKKEEPER_ROLES = ['Internal Bookkeeper - Team Lead', 'Internal Bookkeeper - Member'];

export const ACCOUNTING_ROLES = [
    'International Accounting - Team Lead',
    'International Accounting - Member'
];

export const CS_LEADER_ROLES = [
    'Customer Service - Team Lead - Team 1',
    'Customer Service - Team Lead - Team 2',
    'Customer Service - Team Lead - Team 3',
    'Customer Service - Team Lead - Team 4',
    'Customer Service - Team Lead - Team 5',
    'Customer Service - Team Lead - Team 6'
];

export const CS_MEMBER_ROLES = [
    'Customer Service - Member - Team 1',
    'Customer Service - Member - Team 2',
    'Customer Service - Member - Team 3',
    'Customer Service - Member - Team 4',
    'Customer Service - Member - Team 5',
    'Customer Service - Member - Team 6'
];

export const ALLOWED_ROLES_USE_WHATSAPP = [
    'Director',
    'Vice-Director',
    'Customer Service - Manager',
    'Accounting - Manager',
    'Legal - Team Lead',
    'Legal - Member',
    ...BOOKKEEPER_ROLES,
    ...ACCOUNTING_ROLES,
    ...CS_LEADER_ROLES,
    ...CS_MEMBER_ROLES
];

export const PREVIEW_ORDER_FORM_IDS = [
    1, 4, 10, 16, 17, 24, 25, 32, 42, 48, 52, 56, 62, 69, 76, 77, 82, 83, 87, 88, 89, 149, 212
];

export const ROLES = {
    TESTER_LEADER_ID: 25
};

export const ES_SERVICE = [1841, 1931];
export const AR_SERVICE = [1994, 1993];
export const TIN_SERVICE = [1851];

// Whatsapp
export const MARK_UNREAD = 'MARK_UNREAD';
export const MARK_READ = 'MARK_READ';
export const NEW_FILE_TEMPLATE_IDS = [300, 301, 302, 305, 306];
